.aboutBody {
    display: flex;
    padding: 100px 170px 0px 170px;
    margin-bottom: 166px;

    h1 {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 36px;
        margin: 0 0 20px 0;
        // margin-bottom: 50px;
    }
}

.aboutDesc {
    flex: 1 1 auto;
    //padding-right: 20px; // spacing between text and image

    a {
        color: darkgoldenrod;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
            // add a glow around the text
            text-shadow: 0 0 10px darkgoldenrod;
            filter: brightness(1.2);
            cursor: not-allowed;
        }
    }
}

.aboutImg {
    flex: 1 1 auto;
    max-height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.aboutImg img {
    max-height: 100%;
    width: auto;
    border-radius: 15px;
}

.column {
    column-count: 2; // Split text into columns
}

@media screen and (max-width: 768px) {
    .aboutBody {
        display: block;
        padding: 0px 20px 0px 20px;
        margin-bottom: 10px;
    }

    .aboutImg {
        margin-top: 40px;
    }

    .aboutImg img {
        width: 100%;
    }
}