.mobile-nav {
    display: none; // Hide mobile nav by default
    height: 0px;

    ul {
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            margin-top: 20px;

            a {
                color: #613d3d;
                text-decoration: none;

                &.active {
                    color: darkgoldenrod;
                    text-shadow: 0 0 20px darkgoldenrod;
                }
            }
        }
    }
}

.Header {
    //margin-top: 0px;  
    z-index: 999;
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center; /* Align items vertically */
    
    .logo {
      letter-spacing: .1em;
      line-height: 1.7em;
      font-size: 20px;
    }
  
    .header-nav {
        ul {
            list-style: none; /* Remove list styles */
            display: flex; /* Make the list items flex items */

            li {
                margin-left: 30px; /* Adjust spacing */

                a {
                    text-decoration: none;
                    color: #613d3d;

                    &.active {
                        color: darkgoldenrod;
                    }
                }
            }

            a:hover {
                color: darkgoldenrod;
            }

            li:first-child {
                margin-left: 0;
            }
        }
    }
}

    /* the mobile responsiveness cr*p */

    .menu-btn {
        display: none; // hidden by default
    }

@media screen and (max-width: 768px) {
    .header-nav {
        display: none; // hide nav by default on mobile
    }

    .logo {
        margin:auto;
    }

    .menu-btn { 
        display: block; // display it on mobile
        position: absolute;
        font-size: 18px;
        top:-5px;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: none;
        color: #613d3d;
        border: none;
        cursor: pointer;
        z-index: 1000;
        background: none;
        font-family:Georgia, 'Times New Roman', Times, serif;


        &.show {
            display: none;
        }
    }

    .mobile-nav {
        z-index: 1000;
        display: block; // Display mobile nav on mobile
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-color: white;
        transform: translateY(-100%);
        transition: transform 0.3s ease; // Add transition for smooth animation

        &.show {
            transform: translateY(0); // Move mobile nav into view
        }

        ul {
            display: block;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;

            li {
                margin-top: 20px;

                a {
                    color: #613d3d;
                    text-decoration: none;

                    &.active {
                        color: darkgoldenrod;
                    }
                }
            }
        }
    }
}
  
  