.Footer {
    background-color: #333;
    font-size: 12px;
    color: #fff;
    padding: 10px 0;
    text-align: center;
  
    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    p {
      margin-bottom: 10px;
    }
  
    nav {
      ul {
        list-style-type: none;
        padding: 0;
  
        li {
          display: inline-block;
          margin: 0 10px;
        }
  
        a {
          color: #fff;
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }