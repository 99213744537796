.illustration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0 100px 0;

    .cat-gif {
        width: 100%;
        max-width: 500px;
        margin-bottom: 50px;
    }
}