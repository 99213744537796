.showcase2 {
  h1 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  }
}

.thumbnail-title {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(0);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  // font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;

}

li:hover .thumbnail-title {
  transform: scale(1);
}