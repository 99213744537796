body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 20px 0 0 0;

    h1 {
        font-family:Georgia, 'Times New Roman', Times, serif;
    }

    .page-title {
        font-size: 36px;
        margin-bottom: 50px;
    }

    .page-showcase {
        padding-bottom: 50px;
    }
}