body, html {
  line-height: 1.8em;
  letter-spacing: .04em;
  font-size: 14px;
  background-color: #f5f0e5;
  color: #613d3d;
}

.App {
  text-align: center;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;

  .app-wrapper {
    padding: 15px 60px 40px 60px;
  }

  @media screen and (max-width: 768px) {
    .app-wrapper {
      padding: 25px 25px 25px 25px;
    }
  }
}
